<template>
    <v-dialog v-model="deleteDialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-on="on" v-bind="attrs" color="red">mdi-delete</v-icon>
      </template>
      <v-card>
        <v-card-title class="d-flex justify-center card-title__heading">
          <h2> {{ $t('are you sure you want delete') }} </h2>
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            color="green"
            class="white--text"
            depressed
            @click="deleteRecord"
            :loading="deleteBtnLoading"
            > {{ $t('delete') }} </v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import axios from "axios";
  import Notifications from "../../../../../helpers/toast-notifications";
  
  export default {
    data() {
      return {
        deleteBtnLoading: false,
        deleteDialog: false,
      };
    },
    props: {
      id: [String, Number],
      type: String,
    },
    methods: {
      async deleteRecord() {
        try {
          this.deleteBtnLoading = true;
          if (this.type == "ACCOUNT") {
            await axios.delete(`/accounting/account/${this.id}`);
          } else {
            await axios.delete(`/accounting/folder/${this.id}`);
          }
          this.$emit("record-deleted");
          Notifications(
            "تم الحذف بنجاح",
            { timeout: 2000, rtl: true },
            "success"
          );
          this.deleteDialog = false;
        } catch (err) {
        } finally {
          this.deleteBtnLoading = false;
        }
      },
    },
  };
  </script>
  
  <style></style>
  