<template>
  <v-container>
    <v-container class="d-flex justify-end align-center pt-5 pl-5">
      <v-col  v-if="returnAbility('account:auto-complete')">
        <AutoCompleteField class="pb-6"
                  :label="$t('search')"
                  outlined
                  rounded
                  returnObject
                  @input="onAccountInput"
                  v-model="Account"
                  endPoint="/accounting/account/auto-complete"
                />
      </v-col>
      <app-add-folder v-if="returnAbility('folder:store')" class="pb-5 ml-5" @reFetchTree="fetchData"></app-add-folder>
      <app-add-account class="pb-5" @reFetchTree="fetchData" v-if="returnAbility('account:store')"></app-add-account>
    </v-container>
    <v-card  class="px-15 py-10" :loading="fetchingChild">
      <template slot="progress">
        <v-progress-linear color="#272727" indeterminate> ></v-progress-linear>
      </template>
      <template v-for="(node, i) in treeView">
        <app-tree-view v-if="returnAbility('folder:index')" :key="node.uid + i" :node="node"
          :expandChildren="node.expandChildren" @fetch-my-children="fetchChildren"
          @folderOpened="folderChildrenOpened" @reFetchTree="fetchData"></app-tree-view>
      </template>
    </v-card>
    <v-row justify="center"> </v-row>
    <v-row>
      <v-dialog width="700" v-model="dialog">
        <v-card v-if="Account">
          <v-card-actions class="justify-center">
            <v-row style="cursor: pointer;" @click="handleRowClick()">
              <v-col md="1" class="py-5">
                {{ Account.id }}
              </v-col>
              <v-col md="6" class="py-5">
             
              {{ Account.name}} 
            
              </v-col >
            <v-col md="3" class="d-flex">
          <!-- <v-btn icon><v-icon class="ml-1">mdi-eye</v-icon></v-btn> -->
       
          <EditAccount
            @reFetchTree="fetchData"
            :account="Account"
          />
          
          <deleteAccountFolder  @record-deleted="fetchData"  :type="acc" :id="Account.id" />
        </v-col>
        <v-col md="2">
            <v-btn
              
              depressed
              @click="cancelDialog"
              >{{ $t("cancel") }}</v-btn
            >
          </v-col>
          </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  
  </v-container>
</template>
  
<script>
import axios from "axios";
import treeView from "./components/treeView.vue";
import addAccount from "./components/addAccount.vue";
import addFolder from "./components/addFolder.vue";
import spinner from "../../../../components/base/spinner.vue";
import AutoCompleteField from "../../../dashboard/components/core/AutoCompleteField.vue";
import deleteAccountFolder from "./components/delete.vue";
import EditAccount from './components/editAccount.vue';


export default {
  components: {
    EditAccount,
    deleteAccountFolder,
    AutoCompleteField,
    appSpinner: spinner,
    appAddAccount: addAccount,
    appAddFolder: addFolder,
    appTreeView: treeView,
  },
  data() {
    return {
      acc: "ACCOUNT",
      dialog: false,
      Account: null,
      tree: [],
      copyTree: [],
      fetchingChild: false,
    };
  },
  methods: {
    cancelDialog(){
      this.Account = null;
      this.dialog = false;
    },
    handleRowClick() {
          if(this.returnAbility("account:statement")){
            
          this.$router.push(`/accountStatement/${this.Account.id}`);
          this.dialog = false;
          this.Account = null;
          }
    },
    async onAccountInput(){
      const result = await axios.get(
        `/accounting/account/${this.Account.id}`
      );

      this.Account = result.data.data;
      this.dialog = true;
      

    },
    folderChildrenOpened(node) {
      if (!node.root_id) {
        node.expandChildren = true;
      } else {
        for (let treeItem of this.tree) {
          if (treeItem.id == node.root_id) {
            treeItem.expandChildren = true;

            this.loopThroughTree(treeItem.children, node.root_id);
          }
        }
      }
    },
    loopThroughTree(children, root_id) {
      for (let child of children) {
        child.expandChildren = true;
        child.root_id = root_id;
        this.loopThroughTree(child.children, root_id);
      }
    },
    async fetchChildren(item) {
      for (let treeItem of this.tree) {
        if (treeItem.id == item.root_id) {
          // treeItem.expandChildren = true;
          await this.loopThroughTree(treeItem.children, item.root_id);
        }
      }
      try {
        this.fetchingChild = true;
        const nodeChild = await axios.get("/accounting/folder/index", {
          params: {
            folder: item.id,
          },
        });
        nodeChild.data.data.forEach((el) => {
          item.children = [...el.accountChildren, ...el.foldersChildren];
        });
        for (let child of item.children) {
          child.children = [];
          child.root_id = item.root_id;
        }
        nodeChild.data.data.children = [];
        nodeChild.data.data.expandChildren = false;
        this.tree.forEach((el) => {
          el.uid = this.$uuid.v1();
        });
      } catch (err) {
      } finally {
        this.fetchingChild = false;
      }
    },
    async fetchData() {
      try {
        // if (!this.returnAbility("folder:index")) {
        //   return;
        // }
        this.fetchingChild = true;
        const response = await axios.get("/accounting/folder/index");
        this.tree = response.data.data;
        this.tree.forEach((el) => {
          if (el.model == "FOLDER") {
            el.children = el.accountChildren.concat(el.foldersChildren);
            el.expandChildren = false;
            el.children.forEach((element) => {
              element.root_id = el.id;
              element.children = [];
              element.expandChildren = false;
            });
          }
          el.uid = this.$uuid.v1();
        });
        this.tree.unshift({
          typeee: "heading",
          name: "account name",
          debit: "debtor",
          credit: "credit",
          balance: "account",
          id: -1,
          settings: "settings",
          model: "mah",
        });
      } catch (err) {
      } finally {
        this.fetchingChild = false;
      }
    },
  },
  computed: {
    treeView() {
      let copyTree = [];
      copyTree = this.tree;
      return copyTree;
    },
  },
  created() {
    if (!this.returnAbility("folder:index")) {
      console.log("1");
      return;
    }
    this.fetchData();
  },
};
</script>
  
<style>
.add-modal__btn {
  border: 1px solid #757575 !important;
  background: white !important;
}
</style>